<template>
  <div class="container">
    <div class="search-filter" :style="{ height: isExpansion ? '' : '148px' }">
      <div class="product">
        <div class="label">产品线分类</div>
        <div class="list">
          <div
            class="item"
            :class="{ 'check-item': deviceTypeList.includes(item.name), 'disabled-item': !item.number }"
            @click="handleClassification(item.name, 'deviceTypeList', item.number)"
            v-for="(item, index) in typesOfConstructionMachineryList"
            :key="index"
          >
            {{ item.name }}({{ item.number }})
          </div>
        </div>
      </div>
      <div class="brand">
        <div class="label">品牌分类</div>
        <div class="list">
          <div
            class="item"
            :class="{ 'check-item': partBrandList.includes(item.brandName), 'disabled-item': !(brandNumPageList.find(val=> val.brandName == item.brandName) ? brandNumPageList.find(val=> val.brandName == item.brandName).brandNumber : 0) }"
            v-for="(item, index) in brandInfoPageList"
            @click="handleClassification(item.brandName, 'partBrandList', brandNumPageList.find(val=> val.brandName == item.brandName) ? brandNumPageList.find(val=> val.brandName == item.brandName).brandNumber : 0)"
            :key="index"
          >
            {{ item.brandName }}({{ brandNumPageList.find(val=> val.brandName == item.brandName) ? brandNumPageList.find(val=> val.brandName == item.brandName).brandNumber : 0 }})
          </div>
        </div>
      </div>
      <div class="expansion-box" @click="isExpansion = !isExpansion">
        {{ isExpansion ? "收起" : "展开" }}
      </div>
    </div>
    <GeneralTable
      ref="GeneralTable"
      :column="column"
      :option="option"
      :dataList="dataList"
      :totalNum="totalNum"
      @search-change="getList"
    >
      <template slot="partImage" slot-scope="{ item }">
        <el-image
          style="width: 40px; height: 40px"
          :src="item.partImage || require('@/assets/images/partsRetrieval/parts-defaultIcon.png')"
          :preview-src-list="[item.partImage]"
        >
        </el-image>
      </template>
      <template slot="operate" slot-scope="{ item }">
        <el-button
          class="polecy-button"
          @click.native.prevent="goDetail(item)"
          type="text"
          size="small"
          >查看详情</el-button
        >
      </template>
      <template slot="slot-view">
        <div class="slot-list">
          <div class="item" v-for="(item, index) in dataList" :key="index" @click="goDetail(item)">
            <el-image class="img" :src="item.partImage || require('@/assets/images/partsRetrieval/parts-defaultIcon.png')"></el-image>
            <div class="title">
              {{ item.partName }}
            </div>
            <div class="info">
              <div class="modelNum">{{ item.partModel }}</div>
              <div class="price">
                {{ item.supplierQuotation }}
              </div>
            </div>
            <div class="tags">
              <div class="tag">{{ item.deviceCategory }}</div>
              <div class="tag">{{ item.partBrand }}</div>
            </div>
          </div>
        </div>
      </template>
    </GeneralTable>
  </div>
</template>
<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import {
  getBrandInfoPageList,
  getKindInfoAndNumber,
  getZeroPartsListPage,
} from "@/api/basicData";
export default {
  name: "partsRetrievalListPage",
  components: { GeneralTable },
  data() {
    return {
      dataList: [],
      totalNum: 0,
      option: {
        //   isEdit: true, // 编辑 @edit-change
        isRefresh: true, // 刷新 @search-change
        changeView: true,
        isKeyword: true,
        isSelection: false,
        isHideReference: true,
        isHideActiveNames: true,
        isFirst: false,
        isActivatedFirst: false,
        pageSize: 10,
        searchList: [],
      },
      column: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        {
          label: "配件品号",
          prop: "partNumber",
          isShow: true,
        },
        {
          label: "配件名称",
          prop: "partName",
          isShow: true,
        },
        {
          label: "配件型号",
          prop: "partModel",
          isShow: true,
        },
        {
          label: "配件品牌",
          prop: "partBrand",
          isShow: true,
        },
        {
          label: "配件图片",
          prop: "partImage",
          isShow: true,
          isSlot: true,
        },
        {
          label: "设备类型",
          prop: "deviceType",
          isShow: true,
        },
        {
          label: "设备种类",
          prop: "deviceCategory",
          isShow: true,
        },{
          label: "设备型号",
          prop: "deviceModelNumber",
          isShow: true,
        },
        {
          label: "配件价格",
          prop: "supplierQuotation",
          isShow: true,
        },
        {
          label: "备注",
          prop: "remark",
          isShow: true,
        },
        {
          label: "更新时间",
          prop: "updateTime",
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          width: 120,
          fixed: "right",
          isSlot: true,
        },
      ],
      typesOfConstructionMachineryList: [],
      brandInfoPageList: [],
      partBrandList: [],
      deviceTypeList: [],
      brandNumPageList: [],
      isExpansion: false,
    };
  },
  created() {
    this.init();
  },
  activated() {
      this.$nextTick(() => {
        if (this.$route.query && this.$route.query.deviceType) {
          this.deviceTypeList = [this.$route.query.deviceType];
          this.partBrandList = [];
          this.$refs.GeneralTable.searchData.keyword = '';
        } else if (this.$route.query && this.$route.query.partBrand) {
          this.partBrandList = [ this.$route.query.partBrand ];
          this.deviceTypeList = [];
          this.$refs.GeneralTable.searchData.keyword = '';
        } else if (this.$route.query && this.$route.query.keyword) {
          this.deviceTypeList = [];
          this.partBrandList = [];
            this.$refs.GeneralTable.searchData.keyword = this.$route.query.keyword;
        }
        this.$forceUpdate();
        this.$refs.GeneralTable.getList();
      });
  },
  computed: {},
  methods: {
    init() {
      getKindInfoAndNumber({
        index: 1,
        size: 9999,
      }).then((res) => {
        this.typesOfConstructionMachineryList =
          res.data.typesOfConstructionMachineryList || [];
        this.brandNumPageList = res.data.constructionMachineryBrandList || [];
        this.brandNumPageList.unshift({
          brandName: "全部",
          brandNumber: res.data.allNumber
        })
        this.typesOfConstructionMachineryList.unshift({
          name: "全部",
          number: res.data.allNumber
        })
      });
      getBrandInfoPageList({
        pageNum: 1,
        pageSize: 9999,
        current: 1,
        size: 9999
      }).then(res=>{
        let nameList = res.data.records.map(val=> val.brandName);
        this.brandInfoPageList = res.data.records.filter((item, index) => nameList.indexOf(item.brandName) === index);
        this.brandInfoPageList.unshift({
          brandName: '全部',
        })
      });
      // getKindInfoAndNumber({

      //   index: 1,
      //   size: 9999,
      // }).then(res=>{
      //   console.log(res.data);
      //   this.brandInfoPageList = res.data.records;
      // });
    },
    getList(v, pageNum, pageSize) {
      console.log(this.$store.state);
      getZeroPartsListPage({
        ...v,
        index: pageNum,
        size: pageSize,
        fuzzyLookupField: v.keyword,
        partBrand: this.partBrandList.toString() == '全部' ? '' : this.partBrandList.toString(),
        deviceType: this.deviceTypeList.toString() == '全部' ? '' : this.deviceTypeList.toString(),
        userId: this.$store.state.userInfo.MJUserId
      }).then((res) => {
        this.dataList = res.data.records;
        this.totalNum = res.data.total;
      });
    },
    handleClassification(e, type, number) {
      if (!number) {
        return false;
      }
      // this[type].includes(e) ? this[type].splice(this[type].findIndex ,1) : this[type].push(e);
      this[type].includes(e) ? (this[type] = []) : (this[type] = [e]);
      this.$refs.GeneralTable.getList();
    },
    goDetail(e) {
      console.log("详情");
      this.$router.push({
        path: "/routerPartsRetrieval/partsRetrievalDetails?id=" + e.id,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.search-filter {
  position: relative;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 10px;
  overflow: hidden;
  .product,
  .brand {
    display: flex;
    .label {
      width: 100px;
      height: 28px;
      line-height: 28px;
      white-space: nowrap;
    }
    .list {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      .item {
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        padding: 0 12px;
        border: 1px solid #4278c9;
        margin: 0 15px 15px 0;
        cursor: pointer;
        border-radius: 4px;
        // &:hover {
        //   color: #4278c9;
        //   background-color: #eff6ff;
        // }
      }
      .check-item {
        color: #ffffff;
        background-color: #4278c9;
        border: 1px solid #4278c9;
      }
      .disabled-item {
        opacity: .5;
      }
    }
  }
  .expansion-box {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2px;
    cursor: pointer;
    font-size: 14px;
    color: #0080ff;
    text-align: center;
    background-color: #fff;
  }
}
.slot-list {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 250px;
    padding: 10px;
    border: 1px solid #dee3e6;
    border-radius: 6px;
    margin: 0 15px 15px 0;
    .img {
      width: 230px;
      height: 230px;
      margin-bottom: 8px;
    }
    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      margin-bottom: 8px;
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      .modelNum {
        font-size: 14px;
      }
      .price {
        font-size: 18px;
        color: #fda835;
        font-weight: bold;
      }
    }
    .tags {
      display: flex;
      align-items: center;
      .tag {
        margin-right: 8px;
        padding: 5px 8px;
        background-color: #f2f7fd;
        color: #3470ff;
        border-radius: 5px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
